import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GuidePage = () => (
  <Layout>
    <SEO title="Guide" />
    <div className="edge trim align-baseline site-padding-vertical">
        <h1>External links</h1>
        <div className="grid medium-grid-2 large-grid-4 block-margin-vertical">
            <div className="trim">
                <h3>Color Palettes</h3>
                <p className="text-preset-default-low">Create awesome color palettes!</p>
                <Link className="button button--secondary button--small" to="https://mycolor.space" target="_blank" title="mycolor.space" />
            </div>
        </div>
    </div>
  </Layout>
)

export default GuidePage
